import React from "react";
import LogoSvg from "../../assets/svg/LogoOrange.svg";
import styles from "./Logo.module.css";
import { RouteNames } from "../../routes";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <NavLink to={RouteNames.AIMAP} className={styles.logoWrap}>
      <img src={LogoSvg} alt="Logo" />
      <span className={styles.logoTitle}>aimap</span>
    </NavLink>
  );
};

export default Logo;
