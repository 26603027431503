import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { RouteNames } from "../../../routes";
import styles from "./Navbar.module.scss";
import LogoOrange from "../../../assets/svg/LogoOrange.svg";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { breakpoints, colors, icons } from "../../../constants";
import CustomIcon from "../../../share/CustomIcon/CustomIcon";
import { ReactComponent as BurgerIcon } from "../../../assets/svg/Burger_nobg.svg";
import MobileHamburgerMenu from "../HamburgerMenu";
import useAppContext from "../../../hooks/useAppContext";
import classNames from "classnames";

const Navbar: FC = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${breakpoints.small}px)`,
  });

  const { t } = useTranslation("translation");
  const currentRoute = useLocation().pathname;
  const { isHamburgerMenuOpen, setIsHamburgerMenuOpen } = useAppContext();
  return (
    <>
      <MobileHamburgerMenu
        isOpen={isHamburgerMenuOpen}
        onClose={() => setIsHamburgerMenuOpen(false)}
      />
      <nav data-tag="container" className={styles.nav}>
        <div data-tag={"links"} className={styles.top}>
          {!isMobile && (
            <NavLink to={RouteNames.AIMAP} className={styles.logo}>
              <img src={LogoOrange} alt="logotype" width={32} height={32}></img>
            </NavLink>
          )}
          <NavLink
            className={({ isActive }) =>
              [isActive ? styles.active : "", styles.link].join(" ")
            }
            to={RouteNames.AIMAP}
            onClick={() => {
              setIsHamburgerMenuOpen(false);
            }}
          >
            {!isHamburgerMenuOpen && currentRoute === RouteNames.AIMAP ? (
              <img src={icons.NavMapIconActive} alt="CalendarIcon" />
            ) : (
              <img src={icons.NavMapIcon} alt="CalendarIcon" />
            )}
            <span
              className={classNames({
                [styles.text]: true,
                [styles.active]:
                  !isHamburgerMenuOpen && currentRoute === RouteNames.AIMAP,
              })}
            >
              {t("navbar.map")}
            </span>
          </NavLink>
          {isMobile && (
            <div
              className={styles.link}
              onClick={() => {
                setIsHamburgerMenuOpen((v) => !v);
              }}
            >
              <CustomIcon
                icon={BurgerIcon}
                color={isHamburgerMenuOpen ? colors.redR400 : colors.darkD400}
              />
              <span
                className={classNames({
                  [styles.text]: true,
                  [styles.active]: isHamburgerMenuOpen,
                })}
              >
                {t("navbar.more")}
              </span>
            </div>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
