import React, { useState } from "react";
import styles from "../PasswordRecovery.module.scss";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useActions } from "../../../../hooks/useActions";
import { useTypedSelector } from "../../../../hooks/useTypedSelector";
import { CustomInput, inputTypes } from "../../../../share/Input/Input";
import CloseEye from "../../../../assets/svg/closeEye.svg";
import OpenEye from "../../../../assets/svg/openEye.svg";
import { ButtonLoader } from "../../../../share/Loader/Loader";
import { IProps } from "../PassRecFirstPage/PassRecFirstPage";
import { useNavigate } from "react-router-dom";
import { RouteNames } from "../../../../routes";
import { useTranslation } from "react-i18next";

export interface IFormThirdPage {
  new_password: string;
  re_new_password: string;
}

const PassRecThirdPage = ({ resetPassword }: IProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "passwordRecoveryPage.PassRecThirdPage",
  });

  const schema = yup
    .object<Record<keyof IFormThirdPage, yup.AnySchema>>()
    .shape({
      new_password: yup
        .string()
        .required(t("newPassword.required"))
        .min(8, t("newPassword.min"))
        .max(20, t("newPassword.max")),
      re_new_password: yup
        .string()
        .required(t("newPassword.required"))
        .oneOf([yup.ref("new_password")], t("newPassword.valid")),
    });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormThirdPage>({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const { error, isLoading } = useTypedSelector((state) => state.auth);
  const { passRecThirdStep } = useActions();
  const navigate = useNavigate();
  const [inputPass, setInputPass] = useState("password");
  const [inputRePass, setInputRePass] = useState("password");

  const onSubmit: SubmitHandler<IFormThirdPage> = async ({
    new_password,
    re_new_password,
  }: IFormThirdPage) => {
    const result = await passRecThirdStep(
      +resetPassword?.activation_code,
      resetPassword?.email,
      new_password,
      re_new_password
    );
    if (!!result) {
      navigate(RouteNames.LOGIN);
      window.location.reload();
    }
  };

  const handleChangePassword = () => {
    inputPass === "password" ? setInputPass("text") : setInputPass("password");
  };

  const handleChangeRePassword = () => {
    inputRePass === "password"
      ? setInputRePass("text")
      : setInputRePass("password");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <div className={styles.formWrapper}>
        <label className={styles.passRecLabel} htmlFor="password">
          {t("newPassword.label")}
          <CustomInput
            name="password"
            type={inputPass as inputTypes}
            inputClassName={styles.passRecInput}
            register={register("new_password")}
            isError={!!errors.new_password && !!errors.new_password?.message}
            errorText={errors.new_password?.message}
          >
            <button
              type="button"
              className={styles.eyesButton}
              onClick={handleChangePassword}
            >
              {inputPass === "password" ? (
                <img src={CloseEye} alt="CloseEye" />
              ) : (
                <img src={OpenEye} alt="OpenEye" />
              )}
            </button>
          </CustomInput>
        </label>
        <label className={styles.passRecLabel} htmlFor="rePassword">
          {t("newPasswordRepeat.label")}
          <CustomInput
            name="rePassword"
            type={inputRePass as inputTypes}
            inputClassName={styles.passRecInput}
            register={register("re_new_password")}
            isError={
              !!errors.re_new_password && !!errors.re_new_password?.message
            }
            errorText={errors.re_new_password?.message}
          >
            <button
              type="button"
              className={styles.eyesButton}
              onClick={handleChangeRePassword}
            >
              {inputRePass === "password" ? (
                <img src={CloseEye} alt="CloseEye" />
              ) : (
                <img src={OpenEye} alt="OpenEye" />
              )}
            </button>
          </CustomInput>
        </label>
        {error && <span className={styles.errorMsg}>{error}</span>}
      </div>
      <button
        className={styles.activeEditButton}
        type="submit"
        disabled={isLoading}
      >
        {t("submitBtn")}
        {isLoading ? <ButtonLoader /> : null}
      </button>
    </form>
  );
};

export default PassRecThirdPage;
